@import '../../assets/scss/variables';

#projects-button {
  padding: 1rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 900;
  width: auto !important;
}

.MuiButton-label {
  font-family: 'PoppinsSemiBoldItalic' !important;
}