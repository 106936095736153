@font-face {
  font-family: 'PoppinsBlackItalic';
  src: url('../fonts/Poppins/Poppins-BlackItalic.ttf');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'PoppinsBoldItalic';
  src: url('../fonts/Poppins/Poppins-BoldItalic.ttf');
}

@font-face {
  font-family: 'PoppinsSemiBoldItalic';
  src: url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf');
}

$primary-pink-for-btn: #f50057;

$lg-min: 1367px;
$md-max: 1366px;
$md-min: 768px;
$xs-max: 767px;