// Background colors used for sections (.slice)
$section-color-1: #FFF;
$section-color-2: #fcfcfc;

.sct-color-1{background-color:#FFF}
$border-radius: 0.25rem;
$card-border-radius:       $border-radius;

.sct-color-2{background-color: lightgrey}
$border-radius: 0.25rem;
$card-border-radius:       $border-radius;

.slice{padding-top:4rem;padding-bottom:4rem;position:relative}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 992px){
.container-over-top {
    min-height: 200px;
    margin: -150px auto 0;
    z-index: 10;
    position: relative;
    padding-top: 0;
}
}

// .row{display:-ms-flexbox;-ms-flex-pack:center;-ms-flex-align:center;display:-moz-box;-moz-box-pack:center;-moz-box-align:center;display:-webkit-box;-webkit-box-pack:center;-webkit-box-align:center;display:flex;box-pack:center;box-align:center}

// @media (max-width: 575px){.cols-xs-space>div[class*='col-']:not(:last-child){margin-bottom:3rem}}
// @media (max-width: 767px){.cols-sm-space>div[class*='col-']:not(:last-child){margin-bottom:3rem}}
.col-wrapper--spaced-x{padding-left:3rem;padding-right:3rem}
.heading{margin:0 0 6px;padding:0;text-transform:none;font-family:"Nunito",sans-serif;font-weight:600;color:#111;line-height:1.46}
.heading-xl{font-size:3.75rem !important}
.heading-1 {
  font-size: 2.5rem !important;
  line-height: 1.3;
}
.heading-3 {
  font-size: 1.5rem !important;
  line-height: 1.3;
}
.text-uppercase{text-transform:uppercase !important}
.text-capitalize{text-transform:capitalize !important}

.text-normal {
  text-transform: none !important;
}
.c-gray-light {
    color: #818a91 !important;
}
.font-custom-4 {
    font-family: "Slabo 27px",sans-serif !important;
}
.strong-400 {
    font-weight: 400 !important;
}
.strong-500 {
    font-weight: 500 !important;
}
.strong-600{font-weight:600 !important}
.strong-700{font-weight:700 !important}

.ls-2{letter-spacing:2px !important}
.block {
    margin: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    cursor: default;
    border-radius: $card-border-radius $card-border-radius $card-border-radius;
    -moz-border-radius: $card-border-radius $card-border-radius $card-border-radius;
}

.block .block-image {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
}

.block-image-holder .block-image img {
    max-width: 100%;
}

.block-image-holder:not(.no-radius) .block-image img {
    border-radius: .25rem;
}

.view {
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.view img {
  max-width: 100%;
  display: block;
  position: relative;
}

  .mt-150 {
    margin-top: 150px !important;
  }


.view-first img {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.view-first:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.heading-count {
  font-size: 0.875rem;
  margin-right: 0.625rem;
}

.text-right {
    text-align: right!important;
}

.link {
  color: #3452ff;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.link:hover {
  color: #0022e7;
  text-decoration: none;
  text-decoration: underline;
}

.link-xs {
  font-size: 0.75rem;
}

.link--line-after {
  color: #2b2b2c;
  position: relative;
  padding-right: 50px;
}

.link--line-after:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  height: 1px;
  background-color: #2b2b2c;
}

.link--line-after:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .space-xs-only-3 {
    clear: both;
    margin-bottom: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .space-sm-only-3 {
    clear: both;
    margin-bottom: 3rem;
  }
}

@media (max-width: 575px) {
  .cols-xs-space > div[class*='col-']:not(:last-child) {
    margin-bottom: 3rem;
  }
}

@media (max-width: 767px) {
  .cols-sm-space > div[class*='col-']:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.object-fit {
  object-fit: cover;
}

.object-fit-top{
  object-position: 80% 20%;
}

.vertical-info {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}

.vertical-info>li {
    font-weight: 600;
    margin-right: 0.5rem;
}

.vertical-info>li>p {
    display: inline-block;
    font-size: .875rem;
}

.vertical-info>li {
    color: #2b2b2c;
}

.line-height-1_8 {
    line-height: 1.8 !important;
}

.line-height-3.5 {
    line-height: 3.5 !important;
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.heading-sm {
    font-size: .875rem !important;
}